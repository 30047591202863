import React, { useState } from "react"

import * as styles from "../../../../../pages/docs/portal/form/form.module.css"
import ArrowDown from "../../../../../assets/svg/arrow-down.svg"
import ArrowUp from "../../../../../assets/svg/arrow-up.svg"

export function Dropdown({ placeholder, value, options, onChange }) {
  const [open, setOpen] = useState(false)

  const handleClick = e => {
    e.stopPropagation()

    setOpen(open => !open)
  }

  const optionSelected = options.find(item => item.value === value)

  return (
    <div>
      <div className={styles.dropdown_input} onClick={handleClick}>
        <div>{optionSelected?.label || placeholder}</div>
        <div className={styles.dropdown_icon_container}>
          {open ? (
            <ArrowUp width={20} height={20} />
          ) : (
            <ArrowDown width={20} height={20} />
          )}
        </div>
      </div>
      {open && (
        <div className={styles.dropdown_menu}>
          {options.map(item => (
            <div
              key={item.value}
              className={styles.dropdown_option}
              onClick={e => {
                onChange(item)
                handleClick(e)
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
