import * as React from "react"
import { graphql } from "gatsby"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import * as styles from "./form.module.css"
import { http } from "../../../../services/dataSubjectRequest"
import { i18n } from "../../../../translate/i18n"
import { validateRequiredFields } from "../../../../utils/validateRequiredFields"

import Layout from "../../../../components/layout"
import Grid from "../../../../components/grid"
import { Dropdown } from "../../../../components/pages/docs/portal/form/Dropdown"

function Form({ location }) {
  const date = new Date()
  const day = date.getDay()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const [subject, setSubject] = React.useState("")
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [reqDate, setReqDate] = React.useState(date)
  const [requestDate, setRequestDate] = React.useState(
    `${day}/${month}/${year}`
  )
  const [plannedEndDate, setPlannedEndDate] = React.useState(
    `${day + 1}/${month}/${year}`
  )
  const [request, setRequest] = React.useState("")

  const [sending, setSending] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [success, setSuccess] = React.useState(false)

  async function onSubmit(event) {
    event.preventDefault()

    setErrorMessage("")
    setSending(true)

    const requiredFields = [
      { name: "subject", text: i18n.t("errors.errorSubject") },
      { name: "name", text: i18n.t("errors.errorName") },
      { name: "email", text: i18n.t("errors.errorEmail") },
      { name: "reqDate", text: i18n.t("errors.errorReqDate") },
      { name: "request", text: i18n.t("errors.errorRequest") },
    ]
    const errors = validateRequiredFields({
      requiredFields,
      formAttributes: {
        subject,
        name,
        email,
        reqDate,
        request,
      },
    })

    if (errors.length !== 0) {
      setErrorMessage(errors.join(", "))
      setSending(false)

      return
    }

    try {
      const response = await http.get(
        `/dsar?categoria${subject}&title=${name}&e_mail=${email}&data_da_solicitacao=${requestDate}&solicitacao=${request}&planned_end_date=${plannedEndDate}`,
        {
          headers: {
            "Auth-Token":
              "fff9cc80e41cc9f7fe49356eb7c5f5b5a9facab3abb52a902137ae565bca760c",
            Authorization:
              "81f5993c567d0934088835e8e34105596c370a9acc054f27c24b12c49410486a",
          },
        }
      )

      if (response.data?.errors?.length > 0) {
        const errors = response.data.errors.map(error => error.title)

        setErrorMessage(errors.join(", "))
        setSending(false)

        return
      }

      setSuccess(true)
    } catch (e) {
      setErrorMessage(i18n.t("errors.errorUnexpected"))
    }

    setSending(false)
  }

  React.useEffect(() => {
    if (success) {
      setSubject("")
      setName("")
      setEmail("")
      setReqDate(date)
      setRequestDate(`${day}/${month}/${year}`)
      setPlannedEndDate(`${day + 1}/${month}/${year}`)
      setRequest("")
    }
  }, [success])

  return (
    <Layout location={location} headerHome={false}>
      <Grid>
        <h1 className={styles.page_title}>{i18n.t("form.title")}</h1>
        <div className={styles.content}>
          <p className={styles.text}>{i18n.t("form.p1")}</p>
          <p className={styles.text}>{i18n.t("form.p2")}</p>
          <form className={styles.form} name="form" method="post">
            <div className={styles.container}>
              <div className={styles.field_full_width}>
                <label for="POST-subject">{i18n.t("form.subject")}</label>
                <Dropdown
                  placeholder={i18n.t("form.placeholder")}
                  value={subject}
                  onChange={({ value }) => setSubject(value)}
                  options={[
                    {
                      value: "Dúvidas",
                      label: i18n.t("form.questions"),
                    },
                    {
                      value: "Sugestões",
                      label: i18n.t("form.suggestions"),
                    },
                  ]}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.field}>
                <label for="POST-name">{i18n.t("form.name")}</label>
                <input
                  autoComplete="off"
                  id="POST-name"
                  value={name}
                  name="name"
                  type="text"
                  onChange={({ target: { value } }) => setName(value)}
                />
              </div>
              <div className={styles.field}>
                <label for="POST-email">{i18n.t("form.email")}</label>
                <input
                  autoComplete="off"
                  id="POST-email"
                  value={email}
                  name="email"
                  type="email"
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.field_full_width}>
                <label for="POST-reqDate">{i18n.t("form.reqDate")}</label>
                <DatePicker
                  className={styles.date_picker}
                  selected={reqDate}
                  onChange={date => {
                    const day = date.getDay()
                    const month = date.getMonth() + 1
                    const year = date.getFullYear()

                    setReqDate(date)
                    setRequestDate(`${day}/${month}/${year}`)
                    setPlannedEndDate(`${day + 1}/${month}/${year}`)
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.field_full_width}>
                <label for="POST-request">{i18n.t("form.request")}</label>
                <textarea
                  id="POST-request"
                  name="request"
                  value={request}
                  type="text"
                  onChange={({ target: { value } }) => setRequest(value)}
                  maxLength={1000}
                />
              </div>
            </div>
            <div
              className={styles.footer}
              {...((errorMessage.length > 0 || success) && {
                style: {
                  justifyContent: "space-between",
                },
              })}
            >
              <div
                className={styles.erros_container}
                style={{
                  display: errorMessage.length === 0 ? "none" : "block",
                }}
              >
                <span>{errorMessage}</span>
              </div>
              <div
                className={styles.success_container}
                style={{ display: success ? "block" : "none" }}
              >
                <span>{i18n.t("form.requestSended")}</span>
              </div>
              <button
                disabled={sending}
                className={styles.button}
                onClick={onSubmit}
              >
                {i18n.t("form.send")}
              </button>
            </div>
          </form>
        </div>
      </Grid>
    </Layout>
  )
}

export default Form

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          title
          description
        }
      }
    }
  }
`
